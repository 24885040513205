import { useState } from 'react';
import { Button } from 'primereact/button';
import client, { logout } from '../feathers';
import { ConfirmDialog } from './ConfirmDialog';
import TimeDisplay from './TimeDisplay';
import { InputText } from 'primereact/inputtext';
import { useCurrentUser, useCurrentUserHasRole, useCurrentUserIsSuperAdmin } from '../Persistence/CurrentUserContext';
import { ENUM_ROLES } from '../Enums/ENUM_ROLES';
import { useNavigate } from 'react-router-dom';


const Headline = ({ buttons, subject, setSubject }) => {
    const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
    const isSuperAdmin = useCurrentUserIsSuperAdmin()
    const currentUserHasRole = useCurrentUserHasRole();
    const currentUser = useCurrentUser();
    const navigate = useNavigate();

    const renderConfirmDialog = () => {
        return <ConfirmDialog
            handleOnClick={() => { navigate('/'); logout() }}
            handleOnReject={() => setDisplayConfirmDialog(false)}
            labelCancel='Abbrechen'
            labelOk='Ausloggen'
            message='Wollen Sie sich wirklich abmelden?'
            title='FFW-GD'
            displayConfirmDialog={displayConfirmDialog}
            setDisplayConfirmDialog={setDisplayConfirmDialog}
        />
    }

    return <div className={"flex w-full justify-content-center px-2 align-items-center headline  border-bottom-1 gap-3 surface-border "} style={{ height: '60px' }}>
        {renderConfirmDialog()}
        <div className='flex flex-column mx-3 text-0 text-lg '>
            <img src={`./icons/FFW-GD-Wappen.png`} className='flex align-items-center my-auto w-2rem' />
        </div>
        <div className='hidden md:flex flex-column text-0 text-sm xl:text-lg '>
            <div className='flex justify-content-start'>Feuerwehr</div>
            <div className='flex justify-content-start'>Schwäbisch Gmünd</div>
        </div>
        <div className={`${setSubject ? 'flex' : 'hidden'} mx-2 text-0 text-lg flex-grow-1`}>
            <div className='flex w-full mx-0 md:mx-1 xl:mx-5'>
                <InputText className='w-full' value={subject} onChange={(e) => setSubject(e.target.value)} />
            </div>
        </div>
        <div className={`${setSubject ? 'hidden' : 'flex'} mx-3 text-0 text-lg flex-grow-1`}></div>

        {buttons.map(e =>
            e.hidden ? '' : <Button
                key={e.key}
                className={`text-0 ${e.className}`}
                disabled={e.disabled}
                icon={`pi ${e.icon}`}
                label={e.label}
                onClick={e.command} />

        )}
        <div className={`flex flex-column ml-3 ${currentUserHasRole(ENUM_ROLES.HAUPTWACHE) ? 'text-yellow-500' : 'text-0'}`}>
            <i className='pi pi-power-off text-2xl ' onClick={() => { setDisplayConfirmDialog(true) }} />
        </div>
        <div className={`hidden lg:flex flex-column text-0 ${currentUserHasRole(ENUM_ROLES.HAUPTWACHE) ? 'text-yellow-500' : 'text-0'}`}>
            <div>{currentUser.displayname}</div>
            {currentUserHasRole(ENUM_ROLES.HAUPTWACHE) ? <div onClick={() => { if (isSuperAdmin) client.service('utils').create({ method: 'updateMainMonitor' }) }}>Ansicht Hauptwache</div> : ''}
        </div>
        <div className='hidden lg:flex'>
            <TimeDisplay />
        </div>
    </div>

};

export default Headline;