import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ENUM_ROUTES } from '../Navigation/Routes';
import { Departments } from "../Components/Departments";
import { useMainMonitorList } from "../Persistence/MainMonitorContext";
import Headline from "../Components/Headline";
import { TerminalsMonitor } from "./TerminalsMonitor";
import { useCurrentUserHasRole, useCurrentUserIsMainMonitor } from "../Persistence/CurrentUserContext";
import { ENUM_ROLES } from "../Enums/ENUM_ROLES";
import client, { patchData } from "../feathers";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES";
import { Toast } from 'primereact/toast';
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { SelectButton } from "primereact/selectbutton";
import { ConfirmDialog } from "../Components/ConfirmDialog";
import { TerminalProvider } from "../Persistence/TerminalContext";

const options = [{ name: 'Alarm', value: 1 }, { name: 'Übung', value: 2 }];

export const MainMonitor = () => {
    const currentUserIsMainMonitor = useCurrentUserIsMainMonitor();
    const currentUserHasRole = useCurrentUserHasRole();
    const departmentList = useMainMonitorList();
    const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
    const [displayConfirmPrintDialog, setDisplayConfirmPrintDialog] = useState(false);
    const [departments, setDepartments] = useState([]);
    const [subject, setSubject] = useState('');
    const [disablePrint, setDisablePrint] = useState(true);
    const [listtype, setListtype] = useState(2);
    const [alarmdate, setAlarmdate] = useState(null);
    const [resetdate, setResetdate] = useState(null);


    const toast = useRef(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (departmentList) {
            const depList = departmentList.filter(e => (currentUserHasRole(ENUM_ROLES.HAUPTWACHE) ? e.regdata.length > 0 : e.regdataTerminal.length > 0) || e.regdataUnknown.length > 0)
            setDepartments(depList)
        }
    }, [departmentList])

    useEffect(() => {
        const emptySubject = ((subject || '') === '')
        setDisablePrint(emptySubject)
    }, [subject])

    const printRegdataList = async () => {
        setDisplayConfirmPrintDialog(false)
        const printedDepartments = []
        for (let department of departmentList) {
            const regdataList = currentUserIsMainMonitor ? department.regdata : department.regdataTerminal
            const timeList = regdataList.map(e => e.time)
            const minDate = Math.min(...timeList)
            const alarmdate = minDate
            if (regdataList.length > 0) {
                printedDepartments.push(department)
                await client.service(ENUM_SERVICES.REGDATALIST).create({ resetdate: Date.now(), alarmdate, department, regdata: regdataList, subject, listtype }).then(async (result) => {
                    console.log('result printRegdataList:', result)
                })
            }
        }
        toast.current.show({
            severity: 'success', summary: 'Listen wurden gedruckt', content: (
                <div className="flex w-full">
                    <div className="flex flex-column py-auto px-2">
                        <i className="pi pi-exclamation-triangle " style={{ fontSize: '3rem' }}></i>
                    </div>
                    <div className="flex flex-column ">
                        <div className='text-lg'>Listen für</div>
                        <div className='text-lg font-bold'>{printedDepartments.map((d) => { return <div className='pr-2'>- {d.name}</div> })}</div>
                        <div className='text-lg'>wurden gedruckt</div>
                    </div>
                </div>
            ), life: 7000
        });
    }

    const clearRegdataList = async (regdataListId) => {
        const resetDate = new Date();
        for (let department of departmentList) {
            for (let regdata of department.regdata) {
                if (currentUserIsMainMonitor) {
                    regdata.resetDate = resetDate
                    regdata.regdataListId = regdataListId
                    if (department.manageTerminalList === 0) {
                        regdata.resetOnTerminalDate = resetDate
                        regdata.regdataListTerminalId = regdataListId
                    }
                } else {
                    regdata.resetOnTerminalDate = resetDate
                }
                await patchData(ENUM_SERVICES.REGDATA, regdata).catch((error) => {
                    console.error('Error clearRegdataList:', regdata, error)
                })
            }
        }
    }

    const buttons = [
        {
            key: 'mainMonitorButton_2',
            className: `hidden text-lg ${currentUserIsMainMonitor ? 'xl:flex' : ''}`,
            disabled: departments.length === 0,
            icon: 'pi-trash',
            label: 'Listen löschen', command: () => setDisplayConfirmDialog(true)
        },
        {
            key: 'mainMonitorButton_3',
            className: `hidden text-lg ${currentUserIsMainMonitor ? 'xl:flex' : ''}  `,
            disabled: (departments.length === 0 || disablePrint),
            icon: 'pi-print',
            label: 'Listen drucken', command: () => setDisplayConfirmPrintDialog(true)
        },
        {
            key: 'mainMonitorButton_1',
            className: `hidden text-lg ${currentUserHasRole(ENUM_ROLES.PERSONS) ? 'lg:flex ' : ''} `,
            icon: 'pi-users',
            label: 'Personen', command: () => navigate('/' + ENUM_ROUTES.PERSONADMINISTRATION)
        },
        {
            key: 'mainMonitorButton_4',
            className: `hidden text-lg ${currentUserHasRole(ENUM_ROLES.OPERATIONLISTS) ? 'lg:flex' : ''}  `,
            icon: 'pi-list',
            label: 'Einsatzlisten', command: () => navigate('/' + ENUM_ROUTES.OPERATIONLISTS)
        },

    ]

    return <>
        <Toast ref={toast} />
        <ConfirmDialog
            handleOnClick={() => clearRegdataList()}
            handleOnReject={() => setDisplayConfirmDialog(false)}
            labelCancel='Abbrechen'
            labelOk='Löschen'
            message={`<div class="flex flex-column text-lg gap-1">Liste der Einträgen aller Abteilung<br/>löschen?</div>`}
            title='FFW-GD'
            displayConfirmDialog={displayConfirmDialog}
            setDisplayConfirmDialog={setDisplayConfirmDialog}
        />
        <Dialog
            header="FFW-GD"
            visible={displayConfirmPrintDialog}
            footer={
                <div>
                    <Button label="Abbrechen" icon="pi pi-times" onClick={() => setDisplayConfirmPrintDialog(false)} autoFocus className='button-cancel' />
                    <Button label="Drucken" icon="pi pi-print" onClick={() => printRegdataList()} className="p-button-text" />
                </div>
            }
            onHide={() => setDisplayConfirmPrintDialog(false)}>
            <div className="flex flex-column gap-1 text-lg">
                <div>Alle Listen der Abteilungen als</div>
                <SelectButton
                    id="dialog-print-selectbutton"
                    className="dialog-print-selectbutton my-2"
                    value={listtype}
                    options={options}
                    optionLabel='name'
                    optionValue='value'
                    onChange={(e) => setListtype(e.value)}
                />
                <div>drucken und schließen?</div>
            </div>
        </Dialog>
        <div className={"flex flex-column border-bottom-1 gap-3 surface-border "} style={{ height: '60px' }}>
            <Headline buttons={buttons} showInputField={currentUserIsMainMonitor} subject={subject} setSubject={setSubject} />
        </div>
        <div className='flex'>
            <Departments departments={departments} subject={subject} />
            {currentUserHasRole(ENUM_ROLES.HAUPTWACHE) ? <TerminalProvider><TerminalsMonitor /></TerminalProvider> : ''}
        </div>
    </>

}