import { DropdownChangesChecked } from "../Components/DropdownChangesChecked";
import { InputChangesChecked } from "../Components/InputChangesChecked";
import { InputNumberChecked } from "../Components/InputNumberChecked";

export const emptyDepartment = { id: null, version: 1, active: 1, name: '', autocloseMessage: '', delayOpenRegdata: 600, }

export const DepartmentForm = ({ formik }) => {

    return <div className='flex department-form'>

        <div className='flex flex-column col-6 align-content-start'>
            <InputChangesChecked id='name' className='mb-0' autoFocus={true} type="text" formik={formik} label='Vorname' />
            <InputChangesChecked id='autocloseMessage' tabIndex={0} type="text" formik={formik} label='Nachricht bei automatischem Schliessen' />
            <InputNumberChecked id='delayOpenRegdata' label='Verzögerung für automatisches Schliessen' showButtons={true} min={60} max={1440} step={60} formik={formik} col='col-3' className='w-3rem' />
            <DropdownChangesChecked
                id='manageTerminalList'
                editmode={true}
                label='Liste auf Terminal selbst verwalten'
                formik={formik}
                itemLabel='name'
                itemValue='value'
                className={'bg-white w-full mb-3'}
                options={[{ name: 'ja', value: 1 }, { name: 'nein', value: 0 }]}
            />
            <InputChangesChecked id='regdataListSendto' tabIndex={0} type="text" formik={formik} label='Empfänger für Operative Listen (trenner = ,)' />

        </div>


    </div>
}

export const validateDepartment = (data) => {
    let errors = {};

    return errors;
}