import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import './index.scss';
import reportWebVitals from './reportWebVitals';
import './i18n';
import { Application } from './Application';
import { CurrentUserProvider } from './Persistence/CurrentUserContext';
import { LoginProvider } from './Persistence/LoginContext';
import { ENUM_ROUTES } from './Navigation/Routes';
import { PersonAdministration } from './Administration/PersonAdministration';
import { PersonProvider } from './Persistence/PersonContext';
import { MainMonitor } from './Sites/MainMonitor';
import { DepartmentProvider } from './Persistence/DepartmentContext';
import { MainMonitorProvider } from './Persistence/MainMonitorContext';
import { OperationLists } from './Sites/OperationLists';
import { UserAdministration } from './Sites/UserAdministration';
import { DepartmentAdministration } from './Administration/DepartmentAdministration';

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={
          <CurrentUserProvider>
            <LoginProvider>
              <Application />
            </LoginProvider>
          </CurrentUserProvider>
        }>
          <Route index element={<MainMonitor />} />
          <Route path={`${ENUM_ROUTES.USERADMINISTRATION}`} element={<UserAdministration />} />
          <Route path={`${ENUM_ROUTES.PERSONADMINISTRATION}`} element={<PersonAdministration />} />
          <Route path={`${ENUM_ROUTES.OPERATIONLISTS}`} element={<OperationLists />} />
          <Route path={`${ENUM_ROUTES.DEPARTMENTS}`} element={<DepartmentAdministration />} />
        </Route>

      </Routes>
    </BrowserRouter>
  </React.StrictMode >,
);


reportWebVitals();
