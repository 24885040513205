import { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { useNavigate } from 'react-router-dom';
import { Column } from 'primereact/column';
import Headline from '../Components/Headline';
import { useFormik } from 'formik';
import { copyObject } from '../snippets';
import { EditDialog } from '../Components/EditDialog';
import { getData, patchData } from '../feathers';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { useCurrentUser, useCurrentUserIsSuperAdmin } from '../Persistence/CurrentUserContext';
import { paginatorTemplate } from '../Utils/paginatorTemplate';
import { ENUM_ICONS } from '../Components/Icons';
import { useDepartmentList } from '../Persistence/DepartmentContext';
import { DepartmentForm, emptyDepartment, validateDepartment } from './DepartmentForm';

const CLASSNAME = 'DepartmentAdministration'

export const DepartmentAdministration = () => {
    const [paginationFirst, setPaginationFirst] = useState(0);
    const [paginationRows, setPaginationRows] = useState(50);
    const isSuperadmin = useCurrentUserIsSuperAdmin();
    const dataTableList = useDepartmentList();
    const [mounted, setMounted] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [displayEditDialog, setDisplayEditDialog] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isSuperadmin) {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        if (dataTableList !== null) {
            setTotalRecords(dataTableList.length);
            setMounted(true)
        }

    }, [mounted, dataTableList])

    const getDepartment = async (id) => {
        await getData(ENUM_SERVICES.DEPARTMENTS, id).then(async (user) => {
            formik.setValues(user)
        })
    }

    const patchDepartment = async () => {
        await patchData(ENUM_SERVICES.DEPARTMENTS, formik.values)
    }

    const onDoubleClick = async (e) => {
        await getDepartment(e.data.id)
        setDisplayEditDialog(true);
    }

    const buttons = [
        { key: 'UserAdministrationButton_1', label: 'Hauptwache', command: () => navigate('/') },
    ]

    /** Person formik */
    const formik = useFormik({
        initialValues: copyObject(emptyDepartment),
        validate: validateDepartment,
        enableReinitialze: true,
        validateOnMount: true,
        validateOnChange: true,
    });

    const onValueChanged = (data) => {
        setTotalRecords(data ? data.length : 0)
    }

    const renderFooter = () => {
        return <div>Anzahl der angezeigten Abteilungen: {totalRecords}</div>
    }

    const renderIdColumn = (row) => {
        return <div className='flex gap-2 edit-icon'><i className={'pt-1 ' + ENUM_ICONS.PENCIL} onClick={() => onDoubleClick({ data: row })} /><div>{row.id}</div></div>
    }

    return (!mounted ? <></> :
        <div className='flex flex-column w-full'>

            <EditDialog
                header={formik.values.id ? `Abteilung ${formik.values.lastname}, ${formik.values.firstname} anpassen` : 'Neuen Abteilung anlegen'}
                displayDialog={displayEditDialog}
                setDisplayDialog={setDisplayEditDialog}
                form={<DepartmentForm
                    formik={formik}
                    key='departmentform'
                />}
                formik={formik}
                message='Hier können Sie die Daten der Abteilung anpassen'
                key='editDialogDepartment'
                patchForm={patchDepartment}
            />
            <Headline buttons={buttons} />
            <div className='mx-auto eaa-dataView' style={{ maxWidth: 'calc(100vw - 200px)' }}>
                <DataTable
                    value={dataTableList}
                    onValueChange={onValueChanged}
                    emptyMessage={'keine Einträge gefunden'}
                    scrollHeight={'calc(100vh - 200px)'}
                    selectionMode="single"
                    scrollable
                    removableSort
                    sortField="displayname" sortOrder={1}
                    paginatorTemplate={paginatorTemplate}
                    first={paginationFirst}
                    rows={paginationRows}
                    responsiveLayout="scroll"
                    dataKey="id"
                    stripedRows
                    rowHover
                    footer={renderFooter}
                    onRowDoubleClick={onDoubleClick}
                >
                    <Column header='Id' field='id' style={{ flexGrow: 0, flexBasis: '80px' }} showFilterMenu={false} body={renderIdColumn} />
                    <Column header='Name' field='name' filter style={{ flexGrow: 0, flexBasis: '200px' }} showFilterMenu={false} />
                    <Column header='Listenverwaltung' field='manageTerminalList' filter style={{ flexGrow: 0, flexBasis: '170px' }} showFilterMenu={false} body={(row) => row.manageTerminalList ? 'ja' : 'nein'} />
                    <Column header='Empfänger Operative Listen' field='regdataListSendto' style={{ flexGrow: 1, flexBasis: '200px' }} showFilterMenu={false} />
                    <Column
                        header='Verzögerung für autom. Schliessen'
                        field='delayOpenRegdata'
                        body={(row) => `${row.delayOpenRegdata} Minuten`}
                        style={{ flexGrow: 0, flexBasis: '180px' }}
                    />
                    <Column header='Nachricht bei autom. Schliessen' field='autocloseMessage' style={{ flexGrow: 0, flexBasis: '600px' }} showFilterMenu={false} />
                </DataTable>
            </div>
        </div>

    )
}