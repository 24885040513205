import { useEffect, useState } from 'react';
import { FilterMatchMode } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { useNavigate } from 'react-router-dom';
import { Column } from 'primereact/column';
import { Tooltip } from 'primereact/tooltip';
import Headline from '../Components/Headline';
import { format, parseISO } from 'date-fns';
import { useFormik } from 'formik';
import { copyObject } from '../snippets';
import { EditDialog } from '../Components/EditDialog';
import { getData, patchData } from '../feathers';
import { ENUM_SERVICES } from '../Enums/ENUM_SERVICES';
import { useCurrentUser, useCurrentUserIsSuperAdmin } from '../Persistence/CurrentUserContext';
import { paginatorTemplate } from '../Utils/paginatorTemplate';
import { ENUM_ICONS } from '../Components/Icons';
import { emptyUser, UserForm, validateUser } from './UserForm';
import { useUserList } from '../Persistence/UserContext';
import { Dialog } from 'primereact/dialog';
import { ChangePassword } from '../Login/ChangePassword';
import { ENUM_ROUTES } from '../Navigation/Routes';

const CLASSNAME = 'UserAdministration'

export const UserAdministration = () => {
    const [paginationFirst, setPaginationFirst] = useState(0);
    const [paginationRows, setPaginationRows] = useState(50);
    const [currentPage, setCurrentPage] = useState(1);
    const currentUser = useCurrentUser();
    const isSuperadmin = useCurrentUserIsSuperAdmin();
    const dataTableList = useUserList();
    const [user, setUser] = useState();
    const [filters, setFilters] = useState({
        'id': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'displayname': { value: null, matchMode: FilterMatchMode.IN },
        'permissions': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'roles': { value: null, matchMode: FilterMatchMode.CONTAINS },
    });
    const [mounted, setMounted] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [displayEditDialog, setDisplayEditDialog] = useState(false);
    const [displayPasswordDialog, setDisplayPasswordDialog] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isSuperadmin) {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        if (dataTableList !== null) {
            setTotalRecords(dataTableList.length);
            setMounted(true)
        }

    }, [mounted, dataTableList])

    const getUser = async (id) => {
        await getData(ENUM_SERVICES.USERS, id).then(async (user) => {
            formik.setValues(user)
        })
    }

    const patchUser = async () => {
        const user = copyObject(formik.values)
        delete user.password
        await patchData(ENUM_SERVICES.USERS, user)
    }

    const onDoubleClick = async (e) => {
        await getUser(e.data.id)
        setDisplayEditDialog(true);
    }

    const buttons = [
        { key: 'UserAdministrationButton_1', label: 'Hauptwache', command: () => navigate('/') },
        { key: 'UserAdministrationButton_2', label: 'Personen', command: () => navigate('/' + ENUM_ROUTES.PERSONADMINISTRATION) },
    ]

    /** Person formik */
    const formik = useFormik({
        initialValues: copyObject(emptyUser),
        validate: validateUser,
        enableReinitialze: true,
        validateOnMount: true,
        validateOnChange: true,
    });

    const onValueChanged = (data) => {
        setTotalRecords(data ? data.length : 0)
    }

    const handleChangePassword = async (id) => {
        await getData('users', id).then((_user) => {
            setUser(_user);
            setDisplayPasswordDialog(true);
        })
    }

    const renderIconChangePassword = (rowData) => {
        return <div className="flex justify-content-center w-full changePasswordIcon" data-pr-position='left' data-pr-tooltip={`Passwort für ${rowData.displayname} ändern`} onClick={() => handleChangePassword(rowData.id)}>
            <i className={ENUM_ICONS.USER} />
        </div>
    }

    const renderFooter = () => {
        return <div>Anzahl der angezeigten Personen: {totalRecords}</div>
    }

    const renderIdColumn = (row) => {
        return <div className='flex gap-2 edit-icon'><i className={'pt-1 ' + ENUM_ICONS.PENCIL} onClick={() => onDoubleClick({ data: row })} /><div>{row.id}</div></div>
    }
    const handleHidePasswordDialog = () => {
        setDisplayPasswordDialog(false);
    }

    return (!mounted ? <></> :
        <div className='flex flex-column w-full'>
            <Tooltip target=".changePasswordIcon" className='w-20rem' />
            <Dialog header={'Passwort ändern: ' + (user ? user.displayname : '')} visible={displayPasswordDialog} className='eaa-passworddialog' onHide={() => setDisplayPasswordDialog(false)}>
                <ChangePassword user={user} currentUser={currentUser} hrMode={true} hideDialog={handleHidePasswordDialog} hideLogo={true} />
            </Dialog>
            <EditDialog
                header={formik.values.id ? `Systemnutzer ${formik.values.lastname}, ${formik.values.firstname} anpassen` : 'Neuen Systemnutzer anlegen'}
                displayDialog={displayEditDialog}
                setDisplayDialog={setDisplayEditDialog}
                form={<UserForm
                    formik={formik}
                    key='userform'
                />}
                formik={formik}
                message='Hier können Sie die Daten des Systemnutzers anpassen'
                key='editDialogUser'
                patchForm={patchUser}
            />
            <Headline buttons={buttons} />
            <div className='mx-auto eaa-dataView' style={{ maxWidth: 'calc(100vw - 200px)' }}>
                <DataTable
                    value={dataTableList}
                    onValueChange={onValueChanged}
                    emptyMessage={'keine Einträge gefunden'}
                    scrollHeight={'calc(100vh - 200px)'}
                    selectionMode="single"
                    scrollable
                    removableSort
                    sortField="displayname" sortOrder={1}
                    filterDisplay='row'
                    filters={filters}
                    paginator
                    paginatorTemplate={paginatorTemplate}
                    first={paginationFirst}
                    rows={paginationRows}
                    responsiveLayout="scroll"
                    dataKey="id"
                    stripedRows
                    rowHover
                    footer={renderFooter}
                    onRowDoubleClick={onDoubleClick}
                >
                    <Column header='Id' field='id' style={{ flexGrow: 0, flexBasis: '80px' }} sortable showFilterMenu={false} body={renderIdColumn} />
                    <Column header='Name' field='displayname' filter style={{ flexGrow: 1, flexBasis: '200px' }} sortable showFilterMenu={false} />
                    <Column header='Rollen' field='roles' filter style={{ flexGrow: 1, flexBasis: '200px' }} showFilterMenu={false} />
                    <Column header='Berechtigung' field='permissions' style={{ flexGrow: 0, flexBasis: '160px' }} sortable showFilterMenu={false} />
                    <Column
                        style={{ flexGrow: 0, flexBasis: '40px' }}
                        frozen={true}
                        alignFrozen='right'
                        className='open-icon justify-content-center p-0'
                        body={renderIconChangePassword} />
                </DataTable>
            </div>
        </div>

    )
}